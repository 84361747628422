import React from "react";

import Toolbar from "@mui/material/Toolbar";
import { AppBar, Avatar, Button, CardMedia, IconButton, Stack } from "@mui/material";
import { styles } from "./styles";
import { useState } from "react";
import { Box } from "@mui/system";
import { NAV_BAR_BUTTON, ROUTES } from "utils/common";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Notifications from './components/notifications';
import Profile from './components/profile';

import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import UploadPostPopup from 'components/uploadPostPopup';
import CreateDiscussionPopup from 'components/createDiscussionPopup';
import SwitchAccountPopup from 'components/switchAccountPopup';
import withResponsive from "components/withResponsive";

function Navbar(props) {

  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorE2, setAnchorE2] = useState(null);
  const location = useLocation();

  const [showAddPost, setShowAddPost] = useState(false);
  const [showAddDiscussion, setShowAddDiscussion] = useState(false);
  const [switchAccountsPopup, setSwitchAccountsPopup] = useState(false);



  const handleClickNotification = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseNotification = () => {
    setAnchorEl(null);
  };

  const handleClickProfile = (event) => {
    setAnchorE2(event.currentTarget);
  };
  const handleCloseProfile = () => {
    setAnchorE2(null);
  };

  const navigateToPage = (route) => {
    navigate(`${route}`);
  }

  useEffect(() => {
    const user = localStorage.getItem('USER_DETAILS');
    if (user) {
      const unstringfyData = JSON.parse(user);
      setCurrentUser(unstringfyData);
    }
  }, [])
  console.log(location.pathname);

  return (
    <>
      <AppBar position="static">
        <Toolbar disableGutters>
          <Box sx={styles.logoBox(props.responsiveInfo.isMobile)}>
            <CardMedia
              onClick={() => {
                navigateToPage('');
              }}
              component="img"
              sx={styles.logo(props.responsiveInfo.isMobile)}
              image={require("../../assets/images/verko_logo_text.png")}
            />
          </Box>

          <Box sx={styles.rightBox(props.responsiveInfo.isMobile)} >
            <Stack spacing={props.responsiveInfo.isMobile ? 0 : 5} direction='row' >
              {NAV_BAR_BUTTON.map((item, index) =>
                <Button
                  key={item.name}
                  sx={styles.buttons(location.pathname == item.route, props.responsiveInfo.isMobile)}
                  onClick={() => {
                    navigateToPage(item.route);
                  }}
                  color={location.pathname == item.route ? 'primary' : 'inherit'}
                  startIcon={item.icon(location.pathname == item.route)}>
                  {item.name}
                </Button>)}
            </Stack>

            {currentUser?.userID ?
              <Stack direction="row" spacing={1}>
                <IconButton onClick={handleClickNotification} >
                  <NotificationsNoneIcon
                    color={anchorEl ? '#00abfb' : 'black'}
                  />
                </IconButton>
                <IconButton
                  color={location.pathname === ROUTES.CHATS ? 'primary' : 'default'}
                  onClick={() => {
                    navigateToPage(ROUTES.CHATS)
                  }} >
                  <ChatBubbleOutlineIcon

                  />
                </IconButton>
                <IconButton onClick={handleClickProfile} >
                  <Avatar
                    sx={styles.avatar(location.pathname == `/${currentUser?.username}` || anchorE2, props.responsiveInfo.isMobile)}
                    alt="Profile"
                    src={currentUser?.image || require("../../assets/images/logo.png")}
                  >
                    {currentUser?.username?.length > 0 ? currentUser?.username[0] : 'V'}
                  </Avatar>
                </IconButton>
              </Stack> : props.responsiveInfo.isMobile ? null : <a
                href="https://play.google.com/store/apps/details?id=in.verkoApp"
                target="_blank" rel="noreferrer"
              >
                <CardMedia
                  component="img"
                  sx={{ width: '120px', height: '40px' }}
                  image="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                />
              </a>}


          </Box>

        </Toolbar>
      </AppBar>
      <Notifications
        handleCloseNotification={handleCloseNotification}
        anchorEl={anchorEl}
        openNotification={Boolean(anchorEl)}
        navigateToPage={navigateToPage}
      />
      <Profile
        handleCloseProfile={handleCloseProfile}
        anchorE2={anchorE2}
        openProfile={Boolean(anchorE2)}
        navigateToPage={navigateToPage}
        currentUser={currentUser}
        setShowAddPost={setShowAddPost}
        setShowAddDiscussion={setShowAddDiscussion}
        setSwitchAccountsPopup={setSwitchAccountsPopup}
      />
      <UploadPostPopup setOpen={setShowAddPost} open={showAddPost} />
      <CreateDiscussionPopup setOpen={setShowAddDiscussion} open={showAddDiscussion} />
      <SwitchAccountPopup setOpen={setSwitchAccountsPopup} open={switchAccountsPopup} />
    </>
  );
}

export default withResponsive(Navbar);
