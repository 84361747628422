import { all, fork } from 'redux-saga/effects';

import { watchLogin } from './authSaga';
import {
    watchProfileDetails,
    watchGetUsers,
    watchGetNotFollowedUsers,
    watchTestFollow,
    watchFollow
} from './profileSaga';
import {
    watchCreatePosts,
    watchChangePostStatus,
    watchUpdatePosts,
    watchGetPosts,
    watchClearPosts,
    watchGetUserPosts,
    watchGetNotLikedPosts,
    watchTestReact,

    watchGetPostComments,
    watchPostComment,
    watchPostReact,
    watchGetPostReplies,
    watchPostRemoveComment,
    watchPostRemoveReply,
    watchPostReply,
} from './postSaga';
import {
    watchGetNotifications,
    watchReadNotification,
    watchDeleteNotifcation,
} from './notificationSaga';
import {
    watchGetDiscussionPosts,
    watchGetUserDiscussion,
    watchCreateDiscussion,
    watchUpdateDiscussion,
    watchChangeDiscussionStatus,

    watchGetDiscussionComments,
    watchDiscussionComment,
    watchDiscussionReact,
    watchGetDiscussionReplies,
    watchDiscussionRemoveComment,
    watchDiscussionRemoveReply,
    watchDiscussionReply,
} from './discussionSaga';
import {
    watchGetChats,
    watchGetMessages,
    watchClearChats,
    watchClearMessages
} from './chatSaga';

import {
    watchGetPostTags,
    watchGetServiceLocations,
    watchGetProfessions
} from './utilsSaga';

export default function* rootSaga() {
    yield all([
        fork(watchGetProfessions),
        fork(watchGetServiceLocations),
        fork(watchDiscussionRemoveComment),
        fork(watchDiscussionRemoveReply),
        fork(watchDiscussionReply),
        fork(watchGetDiscussionReplies),
        fork(watchPostRemoveComment),
        fork(watchPostRemoveReply),
        fork(watchPostReply),
        fork(watchGetPostReplies),
        fork(watchGetPostTags),
        fork(watchLogin),
        fork(watchFollow),
        fork(watchGetUsers),
        fork(watchProfileDetails),
        fork(watchGetNotFollowedUsers),
        fork(watchTestFollow),
        fork(watchGetNotLikedPosts),
        fork(watchTestReact),
        fork(watchCreatePosts),
        fork(watchChangePostStatus),
        fork(watchUpdatePosts),
        fork(watchGetPosts),
        fork(watchGetUserPosts),
        fork(watchPostReact),
        fork(watchPostComment),
        fork(watchGetPostComments),
        fork(watchGetChats),
        fork(watchGetMessages),
        fork(watchClearChats),
        fork(watchClearMessages),
        fork(watchGetDiscussionPosts),
        fork(watchGetUserDiscussion),
        fork(watchGetDiscussionComments),
        fork(watchCreateDiscussion),
        fork(watchUpdateDiscussion),
        fork(watchChangeDiscussionStatus),
        fork(watchDiscussionComment),
        fork(watchDiscussionReact),
        fork(watchClearPosts),
        fork(watchGetNotifications),
        fork(watchReadNotification),
        fork(watchDeleteNotifcation),
    ]);
}
